import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { Box, Stack } from '../../components/Core';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import SectionWithTitle from '../../components/SectionWithTitle';
import SEO from '../../components/SEO';
import SocialShareIcons from '../../components/SocialShareIcons';
import Text from '../../components/Text';
import { TEXT_STYLES } from '../../components/Text/constants';
import useReplaceWPGalleryLinks from '../../hooks/useReplaceWPGalleryLinks';
import { IWordpressImage } from '../../types/wordpress/blocks';
import {
  createImageWithArtDirection,
  serializeImageForImageComponent,
} from '../../utils/serializeImageForImageComponent';

export interface IActualite {
  acfActualite: {
    sectionHero: {
      imageMobile: IWordpressImage | null;
      titre: string | null;
    };
  };
  content: string;
  date: string;
}

type IActualiteTemplate = PageProps<Queries.PageActualiteQuery, IPageContext>;

const ActualiteTemplate: FCWithClassName<IActualiteTemplate> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpActualite;
  if (!pageData || !pageContext.defaultLanguage) return null;
  const postContentRef = useReplaceWPGalleryLinks();
  const post: IActualiteTemplate['data']['wpActualite'] = pageData;
  const imageWithArtDirection = createImageWithArtDirection({
    desktopImage: post.featuredImage?.node,
    mobileImage: post.acfActualite?.sectionHero?.imageMobile,
  });
  const formattedDate = post.date
    ? new Date(post.date).toLocaleDateString()
    : '';
  const publishedOnText =
    data.allWpConfiguration.nodes[0].configuration?.textes?.publishedOn;
  const formattedPublishedOnText = publishedOnText
    ?.replace('%DATE%', formattedDate)
    .replace('%AUTEUR%', 'Uperform');
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Stack className="space-y-gap mt-gap">
        <SectionWithTitle contentAlignment="center">
          <Stack align="center" className="w-full space-y-12">
            <Text
              variant={TEXT_STYLES.H1}
              className="text-center !normal-case !text-5 md:text-6"
            >
              {post.acfActualite?.sectionHero?.titre}
            </Text>
            <Text>{formattedPublishedOnText}</Text>
            <SocialShareIcons location={props.location.href} />
            {post.featuredImage && (
              <div className="w-full aspect-h-6 aspect-w-4 sm:aspect-w-6 md:aspect-w-8 xl:aspect-w-14">
                <Image
                  image={serializeImageForImageComponent({
                    image: imageWithArtDirection,
                  })}
                />
              </div>
            )}
            <Box
              className="w-full max-w-3xl text-lg prose"
              ref={postContentRef}
              dangerouslySetInnerHTML={{
                __html: post.content ?? '',
              }}
            />
            <SocialShareIcons location={props.location.href} />
          </Stack>
        </SectionWithTitle>
      </Stack>
    </Layout>
  );
};

export default ActualiteTemplate;

export const query = graphql`
  query PageActualite($id: String, $currentLanguageSlug: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    wpActualite(id: { eq: $id }) {
      acfActualite {
        sectionHero {
          imageMobile {
            ...MediaItemFragment
            localFile {
              ...MobileHeroImage
            }
          }
          titre
        }
      }
      content
      date
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
    }
    allWpConfiguration(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        configuration {
          textes {
            publishedOn
          }
        }
      }
    }
  }
`;
